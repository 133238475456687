import React from 'react';
import {
  ButtonFooter,
  CustomModal,
  HeaderMessageModal,
  HeaderModal,
  ImageNotificationArea,
  ModalContent,
  ModalInformationsContent,
} from './styles';
import notificationAlert from '../../../../img/notification-alert.svg';
import arrowPrev from '../../../../img/arrowPrev.svg';
import arrowRight from '../../../../img/arrowRight.svg';
import { NotificationItem } from '../../../../contexts/notifications';
import config from '../../../../config';

function ModalNotifications({
  handleCloseModal,
  notificationModal,
  openModal,
  returnDate,
}: ModalNotificationsProps) {
  return (
    <CustomModal
      isOpen={openModal}
      onRequestClose={handleCloseModal}
      style={{
        overlay: {
          background: 'rgba(106, 106, 106, 0.80)',
          zIndex: 1000,
        },
      }}
      contentLabel="Modal Notificações"
    >
      <ModalInformationsContent>
        <HeaderModal>
          <button onClick={handleCloseModal}>
            <img src={arrowPrev} alt="Voltar" />
          </button>
          <h3>Mensagem</h3>
        </HeaderModal>
        <ModalContent>
          <HeaderMessageModal>
            <img src={notificationAlert} alt="Notificação" />
            <h3>{notificationModal?.title}</h3>
          </HeaderMessageModal>
          <div dangerouslySetInnerHTML={{ __html: notificationModal?.text || '' }} />
          {notificationModal?.image && (
            <ImageNotificationArea>
              <img src={config.DOWNLOAD_URL + notificationModal?.image} alt="Notificação" />
            </ImageNotificationArea>
          )}
          <span>{returnDate(notificationModal?.post_date)}</span>
          {notificationModal?.link && (
            <ButtonFooter onClick={() => window.open(notificationModal?.link || '', '_blank')}>
              <p>Quero saber mais</p>
              <img src={arrowRight} alt="Quero saber mais" />
            </ButtonFooter>
          )}
        </ModalContent>
      </ModalInformationsContent>
    </CustomModal>
  );
}

interface ModalNotificationsProps {
  openModal: boolean;
  handleCloseModal: () => void;
  notificationModal: NotificationItem | null;
  returnDate: (date?: string) => string | undefined;
}

export default ModalNotifications;
