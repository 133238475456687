import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

// general components
import PropTypes from 'prop-types'
import { Layout, Button } from 'antd'
import { useParams } from 'react-router-dom'
import { CloseCircleOutlined, CheckCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import MainLayout from '../../components/MainLayout'
import LectureVideoQuestion from '../course/components/class/lecture/LectureVideoQuestion.component'

// redux + selectors
import * as questionSelectors from '../../redux/selectors/question'
import { actions as questionActions } from '../../redux/modules/question';

import SmileSadPNG from '../../img/smile-sad.png';
import './styles.scss';


// Question context creation
export const QuestionContext = React.createContext({})

 // workaround due problems styling unordered list bullet with flexbox
 const alphabeticalOrder = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']

const propTypes = {
    question: PropTypes.object,
    getQuestion: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
}

function QuestionByCode(props) {
    const { questionCode } = useParams()
    const { getQuestion, isLoading, question } = props

    useEffect(() => {
        getQuestion(questionCode)
        // eslint-disable-next-line
    }, []);


    const [questionVideoStart, setQuestionVideoStart] = useState(false)
    const [videoToShow, setVideoToShow] = useState()

    const handleStartQuestionVideo = videoEmbed => {
        setVideoToShow(videoEmbed)
        setQuestionVideoStart(true)
    }

    const renderAnswerIcon = answer => {
        if ((answer.marcada && answer.correta === 1) || answer.correta === 1) {
            return <CheckCircleOutlined style={{ fontSize: 36, marginRight: 10, marginLeft: 10 }} />
        } else if (answer.correta !== 1 && answer.marcada) {
            return <CloseCircleOutlined style={{ fontSize: 36, color: '#DE0000', marginRight: 10, marginLeft: 10 }} />
        }
    }

    const renderImg = (img) => {
        if (img) {
          const domain = 'https://arquivos.academiarafaeltoro.com.br'
          const path = 'uploads/'
          const imgWithPath = img.indexOf(path) >= 0 ? img : path + img
          return <img src={`${domain}/${imgWithPath}`} alt="" style={{ marginTop: 10 }} />
        }
        return ''
      }

    return (
      !isLoading  && (

        <>

        {
            !question?.codigo ? (
                <MainLayout className="question" title={[{ label: 'Questão Respondida' }, { label: question.codigo }]}>
                    <Layout>
                        <div className="not__found">
                            <img src={SmileSadPNG} alt="triste" />
                            <h1>Ops ! Não foi possível encontrar a questão solicitada.</h1>
                        </div>
                    </Layout>
                </MainLayout>
            ) : (
                <MainLayout className="question" title={[{ label: 'Questão Respondida' }, { label: question.codigo }]}>
                    <Layout>
                        <LectureVideoQuestion
                            questionVideoStart={questionVideoStart}
                            setQuestionVideoStart={setQuestionVideoStart}
                            videoEmbed={videoToShow}
                        />
                        <div className="lectureReviewContainer">
                        <div className="ant-modal-body">
                            <div key={`question-${question.codigo}`}>
                            <div className='questionDescription'>
                                <h1>Questão {question.codigo}</h1>
                                <p
                                style={{ whiteSpace: 'pre-wrap' }}
                                >
                                ({question.codigo}) {question.enunciado} {renderImg(question.img_enunciado)}
                                </p>
                            </div>
                            {question.video_embed && <div className="questionVideoContainer">
                                <Button onClick={() => handleStartQuestionVideo(question.video_embed)}>
                                <PlayCircleOutlined style={{ fontSize: 20 }} />Ver questão comentada
                                </Button>
                            </div>}
                            <div className="questionAnswersContainer">
                                <ul>
                                {question.awnsers != null ? question.awnsers.map((answer, index) => {
                                    return (
                                    <>
                                        <li key={`answer-${index}`} className={answer.correta === 1 ?
                                        'correta' : (answer.marcada ? 'selected' : '')}>
                                            {`${alphabeticalOrder[index]}. `}
                                            {answer.resposta}
                                            {renderAnswerIcon(answer)}
                                        </li>
                                    </>
                                    )
                                }) : ''}
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                    </Layout>
                </MainLayout>
            )
        }
      </>
    )
  )
}

const mapStateToProps = (state, props) => ({
    question: questionSelectors.question(state, props),
    isLoading: questionSelectors.isLoading(state, props),
})
const mapDispatchToProps = (dispatch) => ({
    getQuestion: (questionCode, next) => dispatch(questionActions.getQuestionByCode(questionCode, next)),
})

QuestionByCode.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(QuestionByCode)
