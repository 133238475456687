import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 0 0;

  padding: 10px;

  gap: 30px;

  overflow: auto;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    padding: 0;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 8px;
`;

const Tipography = styled.p`
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  letter-spacing: 0.2px;

  margin: 0;
`;

export const Title = styled(Tipography)`
  color: #101010;
  font-size: 24px;

  font-weight: 500;
`;

export const Subtitle = styled(Tipography)`
  font-family: "Manrope", sans-serif;
  color: #858585;
  font-size: 16px;

  font-weight: 400;
`;

interface TitleItemProps {
  margin?: string;
}

export const TitleItem = styled(Tipography)<TitleItemProps>`
  color: #101010;

  font-size: 20px;

  font-weight: 500;

  margin: ${({ margin }) => margin || '0'};
`;

export const Panel = styled.div`
  width: 100%;
  height: 100%;
  min-height: 865px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 0 0;

  background: #ffffff;

  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);

  padding-left: 24px;

  border-radius: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: fit-content;

    padding: 0;
  }
`;

export const LeftBar = styled.div`
  width: 323px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding-right: 24px;

  border-right: 1px solid #efefef;

  @media screen and (max-width: 768px) {
    flex-direction: row;

    width: 100%;
    padding: 0;

    border: none;

    margin-top: -12px;
  }
`;

interface MenuItemsProps {
  isLastItem?: boolean;
  isSelected?: boolean;
  isFirstItem?: boolean;
}

export const MenuItems = styled.button<MenuItemsProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 8px;

  padding: 8px 16px 14px 16px;
  margin-top: 14px;

  text-align: left;

  background: transparent;

  border: none;
  border-bottom: ${({ isLastItem }) => (isLastItem ? 'none' : '1px solid #efefef')};

  cursor: pointer;

  transition: all 0.2s;

  > p {
    width: 100%;

    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.48px;

    color: ${({ isSelected }) => (isSelected ? '#5768FF;' : '#858585')};
  }

  > svg {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};

    transition: all 0.2s;
  }

  &:hover {
    color: #3f4cbd;

    > svg {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    border: none;

    padding: 6px 10px;

    background: ${({ isSelected }) => (isSelected ? '#5768FF;' : 'transparent')};

    border-radius: ${({ isFirstItem, isLastItem }) =>
      isFirstItem ? '10px 0 0 0' : isLastItem ? '0 10px 0 0' : '0'};

    > p {
      text-align: center;
      color: ${({ isSelected }) => (isSelected ? '#EFEFEF' : '#858585')};
    }

    > svg {
      display: none;
    }
  }
`;

export const ButtonDeleteAccount = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  padding: 8px 16px;

  margin: 36px 0;

  background: transparent;
  border: none;

  color: #d81e5b;
  text-align: center;

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.48px;

  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    filter: brightness(0.7);
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    text-align: center;

    margin: 0 auto 18px auto;

    background: transparent;
  }
`;

export const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  padding: 24px;

  gap: 30px;

  > span > div.ant-upload-select-picture-card {
    border: none;
  }
`;

export const EditAvatarItem = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 0;
  right: 0;

  width: 24px;
  height: 24px;

  border-radius: 35px;

  background: #5768ff;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  z-index: 2;
`;

export const AvatarArea = styled.div`
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 86px;
  height: 100%;
  border-radius: 120px;

  border-radius: 120px;

  background: #efefef;
  border: 2px solid #5768ff;

  > img {
    border-radius: 120px;
  }
`;

export const NameLetterIcon = styled.p`
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.96px;

  color: #5768ff;
`;

const ButtonDeleteArea = styled.div`
  width: 100%;

  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 8px;
`;

export const ButtonDeleteMenuArea = styled(ButtonDeleteArea)`
  display: flex;

  margin-top: auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ButtonDeleteFooterArea = styled(ButtonDeleteArea)`
  display: none;
  margin-top: 48px;
  margin-bottom: -24px;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;
