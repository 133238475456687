import React from 'react';

import { AccountBookOutlined } from '@ant-design/icons';

import Breadcrumb from '../Breadcrumb';

import { Container } from './styles';

interface HeaderProps {
  title: string;
  icon?: any;
  course: {
    id_course_category: number;
    scheduledTest?: {
      date_exam: string;
    };
  };
  setSelectedClass: (className: string) => void;
}

function Header({ title, icon: Icon = AccountBookOutlined }: HeaderProps) {
  return (
    <Container data-testid="header-test">
      <h1 className="header-title">
        <Icon data-testid="icon-header-test" />
        <Breadcrumb title={title} />
      </h1>
    </Container>
  );
}

export default Header;
