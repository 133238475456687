import React, { useContext, useEffect } from 'react'

import { connect } from 'react-redux'

import config from '../../../../config'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import {  ArrowRightOutlined } from '@ant-design/icons'
import CardMaterial from '../../../../components/CardMaterial';
import  { TitleIntro, BoxMaterial , Container } from './styles';
import { Col } from 'antd';
//
// context
import { CourseContext } from '../../ClassContent/CourseDetail.container'

//
// redux + selectors
import * as authSelectors from '../../../../redux/selectors/auth'
import { actions as classActions } from '../../../../redux/modules/class'
import { actions as courseActions } from '../../../../redux/modules/course'

const propTypes = {
  user: PropTypes.object,
  registerPdfDownload: PropTypes.func.isRequired,
  setClassProperties: PropTypes.func.isRequired
}

function ClassFile(props) {
  const {
    selectedClass,
    setClassStep,
    setSelectedClass,
    course,
    automaticDownloadPDF,
    setAutomaticDownloadPDF
  } = useContext(CourseContext);

  /*const handlePdfDownload = () => {
    const { registerPdfDownload, user, setClassProperties } = props
    registerPdfDownload(user.id, course.tag, selectedClass.id, () => {
      if(pdf_file.includes('uploads')){
        let node = document.createElement('a')
        node.href = `${config.s3Url}${pdf_file}`
        node.click()
        //window.open(`${config.s3Url}${pdf_file}`)
      }else{
        let node = document.createElement('a')
        node.href = `${config.s3Url}uploads/${pdf_file}`
        node.click()
        //window.open(`${config.s3Url}uploads/${pdf_file}`)
      }
      // update class list
      setClassProperties(selectedClass, { downloaded_pdf: true }, newSelectedClass => {
        setSelectedClass(newSelectedClass)
      })
    })
  }*/

  const handlePdfDownload = (e) => {
    const { registerPdfDownload, user, setClassProperties } = props

    registerPdfDownload(user.id, course.tag, selectedClass.id, () => {
      // update class list
      setClassProperties(selectedClass, { downloaded_pdf: true }, newSelectedClass => {
        setSelectedClass({...newSelectedClass, downloaded_pdf: true });
        e && window.open(`${config.s3Url}${pdf_file}`, '_blank');
      })
    })
  };


  const { pdf_file } = selectedClass

  const existVideo = Object.keys(selectedClass).find(element => element === 'aula_videos') &&
  selectedClass.video_embed  ?
    selectedClass.video_embed:
    selectedClass.aula_videos.length > 0
      ? selectedClass.aula_videos[0].video_embed
      : ''

  if(!pdf_file && (selectedClass.texto === null || selectedClass.texto.trim().length === 0)) {
    if(existVideo) {
      setClassStep('video')
    } else if(selectedClass.have_lecture) {
      setClassStep('lecture')
    }
  };

  useEffect(() => {
    if (automaticDownloadPDF && selectedClass && pdf_file) {
      handlePdfDownload();
      setAutomaticDownloadPDF(false);
    }

  //eslint-disable-next-line
  }, [selectedClass, pdf_file, automaticDownloadPDF])

  return (
    <>
      {selectedClass && pdf_file && (

      <Container>
        <Col>
          <TitleIntro>Aqui você vai encontrar todo o seu material de aula. </TitleIntro>
        </Col>
        <BoxMaterial className="box-material">
          <CardMaterial
            titleClass={"Baixe o Material da aula"}
            titleButton="Baixe o Material"
            type="PDF"
            onClick={handlePdfDownload}
            href={`${config.s3Url}${pdf_file}`}
            target='_blank'
            rel='noopener noreferrer'
          />
        </BoxMaterial>
      </Container>
      )}
      {(!selectedClass || !pdf_file) && (
        <>
        <div className="pdf-section-content no-pdf-section-content">
          <h2>Sobre <span>a aula</span></h2>
          <p dangerouslySetInnerHTML={{
              __html: selectedClass.texto}}></p>
          <Button
            type="primary"
            className="ant-btn ant-btn-primary pdf-button"
            onClick={() => {
              setClassStep('video')
            }}
          >
            Ver o video
            <ArrowRightOutlined />
          </Button>
        </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  user: authSelectors.getUser(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  registerPdfDownload: (userId, courseTag, classId, next) =>
    dispatch(classActions.registerPdfDownload(userId, courseTag, classId, next)),
  setClassProperties: (selectedClass, step, next) =>
    dispatch(courseActions.setClassProperties(selectedClass, step, next))
})

ClassFile.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(ClassFile)
