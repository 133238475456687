import React, { useEffect } from 'react';
import { Row } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import * as courseSelectors from '../../redux/selectors/course';
import * as authSelectors from '../../redux/selectors/auth';
import { actions as courseActions } from '../../redux/modules/course';

import MainLayout from '../../components/MainLayout';
import CourseCard from './components/CourseCard.component';
import PropTypes from 'prop-types';

import CourseListEmpty from './components/CourseListEmpty';

const propTypes = {
  getCoursesByUserId: PropTypes.func.isRequired,
  fetchPodcast: PropTypes.func.isRequired,
  userId: PropTypes.any.isRequired,
  enrollmentsList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired
};
function CourseList({
  getCoursesByUserId,
  isLoading,
  enrollmentsList
}) {

  useEffect(() => {
    getCoursesByUserId();
    // eslint-disable-next-line
  }, [])

  return (
    isLoading ? <CourseListEmpty /> : (
        <MainLayout title='Meus cursos' className="home">
            <Row gutter={15}>
            {enrollmentsList.filter((course) => {
                let isOpenCourseClass = true
                if(course?.data_fim_aulas){
                    const dataFim = moment(course.data_fim_aulas, 'YYYY-MM-DD').add(1, 'days').format('YYYY/MM/DD')
                    const dataAtual = moment().format('YYYY/MM/DD')
                    isOpenCourseClass = moment(dataFim).isAfter(dataAtual)
                }

                return isOpenCourseClass;
            })
            .map((course, index) => (
                <CourseCard key={`course-${index}`} course={course} />
            ))}
            </Row>
        </MainLayout>
    )
  );
}

const mapStateToProps = (state, props) => {
   return {
    enrollmentsList: courseSelectors.getEnrollmentsList(state, props),
    isLoading: courseSelectors.isLoading(state, props),
    userId: authSelectors.getUser(state, props).id
  };

};

const mapDispatchToProps = (dispatch) => ({
  getCoursesByUserId: (next, error) => dispatch(courseActions.getCoursesByUserId(next, error)),
});

CourseList.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
