import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { fetchAllNotifications, readNotification } from '../../redux/api/notifications';
import _ from 'lodash';

export interface NotificationItem {
  id: string;
  title: string;
  link?: string;
  text: string;
  read: boolean;
  created_at: string;
  image?: string;
  applications?: string;
  post_date: string;
}

interface NotificationsContextData {
  notifications: NotificationItem[];
  handleReadNotification: (id: string) => void;
  isLoading: boolean;
  getNotifications: (page: number) => void;
  finishedNotificationsList: boolean;
}

interface NotificationsProviderProps {
  isReport: boolean;
  children: ReactNode;
}

export const NotificationsContext = createContext<NotificationsContextData>(
  {} as NotificationsContextData
);

const NotificationsProvider = ({ isReport, children }: NotificationsProviderProps) => {
  const [finishedNotificationsList, setFinishedNotificationsList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  useEffect(() => {
    if (!isReport) {
      getNotifications(1);
    }
    // eslint-disable-next-line
  }, [isReport]);

  useEffect(() => {
    if (!isReport) {
      const interval = setInterval(() => {
        getNotifications(1);
      }, 10000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [isReport]);

  function sortNotifications(notifications: NotificationItem[]): NotificationItem[] {
    return notifications.sort((a, b) => {
      if ((a.read === null || a.read === false) && b.read !== null && b.read !== false) {
        return -1;
      } else if (a.read !== null && a.read !== false && (b.read === null || b.read === false)) {
        return 1;
      }

      const dateA = new Date(a.post_date);
      const dateB = new Date(b.post_date);

      return dateB.getTime() - dateA.getTime();
    });
  }

  const getNotifications = async (page: number) => {
    if (window.location.pathname === '/auth/login') return;

    setIsLoading(true);
    try {
      const resultsNotifications = await fetchAllNotifications({ page });
      if (resultsNotifications.length === 0) setFinishedNotificationsList(true);

      setNotifications((prevNotifications) => {
        const concatNotifications = _.concat(prevNotifications, resultsNotifications);
        const removeDuplicated = _.uniqBy(concatNotifications, 'id');
        const notificationsSorted = sortNotifications(removeDuplicated);
        return notificationsSorted;
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReadNotification = async (id: string) => {
    try {
      await readNotification(id);
      setNotifications((state) =>
        state.map((notification) => {
          if (notification.id === id) {
            return { ...notification, read: true };
          }
          return notification;
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        handleReadNotification,
        isLoading,
        getNotifications,
        finishedNotificationsList,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};

export default NotificationsProvider;
