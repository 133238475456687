import React from 'react'

import PropTypes from 'prop-types'

import { Modal } from 'antd'
import RenderVideo from '../../../../../components/RenderVideo.component'

import {CloseOutlined} from '@ant-design/icons'

import './styles/modal.scss'

const propTypes = {
  questionVideoStart: PropTypes.bool,
  setQuestionVideoStart: PropTypes.func,
  videoEmbed: PropTypes.string,
  setModalVideoOption: PropTypes.func,
  setLSBVideo: PropTypes.func,
}

function LectureVideoQuestion(props) {

  const { videoEmbed, questionVideoStart, setQuestionVideoStart, setModalVideoOption, setLSBVideo } = props
  
  return (
    <Modal
      width={800}
      zIndex={10001}
      footer={false}
      closable={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={questionVideoStart}
      onCancel={() => setQuestionVideoStart(false)}
      afterClose={() => {
        setLSBVideo(false)
        setModalVideoOption(false)
      }}
      wrapClassName="lectureContainerVideo"
      className='modal-lecture-video'
      closeIcon={<button className='button-close'><CloseOutlined /></button>}
    >
      <RenderVideo videoEmbed={videoEmbed} />
    </Modal>
  )
}

LectureVideoQuestion.propTypes = propTypes
export default LectureVideoQuestion