import React from 'react'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ title }) => {
  return (
    <AntBreadcrumb>
      {typeof title === 'string' ? (
        <AntBreadcrumb.Item>{title}</AntBreadcrumb.Item>
      ) : (
          (title || []).map(({ label, path }, i) => {
            return (
              <AntBreadcrumb.Item key={i}>
                {path ? <Link to={path}>{label}</Link> : <span>{label}</span>}
              </AntBreadcrumb.Item>
            )
          })
        )}
    </AntBreadcrumb>
  )
}

export default Breadcrumb
