//eslint-disable-next-line
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import MainLayout from '../../components/MainLayout';
import { NotificationItem, useNotifications } from '../../contexts/notifications';
import {
  ButtonLoadingMoreNotifications,
  Container,
  NotificationArea,
  NotificationAreaItem,
  NotificationDescriptionArea,
  NotificationFooter,
  Panel,
  PanelNoNotification,
  SkeletonLoadingArea,
  Title,
  TitleArea,
} from './styles';
import noNotifications from '../../img/no-notifications.svg';
import notificationAlert from '../../img/notification-alert.svg';
import notificationsAlertDisabled from '../../img/notifications-alert-disabled.svg';
import checkedActive from '../../img/checked-active.svg';
import checkedDisabled from '../../img/checked-disabled.svg';
import './styles-antd.scss';
import moment from 'moment';
import 'moment/locale/pt-br';
import ModalNotifications from './components/ModalNotifications';
import Skeleton from 'react-loading-skeleton';

moment.locale('pt-br');

function Notifications() {
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState<NotificationItem | null>(null);
  const [sortedUnreadFirst, setSortedUnreadFirst] = useState<NotificationItem[] | null>(null);
  const heigthPanel = document.documentElement.clientHeight - 242;
  const {
    notifications: notificationsApi,
    handleReadNotification,
    isLoading: notificationsLoading,
    getNotifications,
    finishedNotificationsList,
  } = useNotifications();

  const hasNotifications = notificationsApi?.length > 0;
  const unreadNotifications = notificationsApi?.filter(
    (notification) => !notification.read
  )?.length;

  useEffect(() => {
    if (notificationsApi) {
      const sorted = notificationsApi.sort((a, b) => {
        if (a.read && !b.read) return 1;
        if (!a.read && b.read) return -1;
        return 0;
      });
      setSortedUnreadFirst(sorted);
    }
  }, [notificationsApi]);

  const handleOpenNotification = (notification: NotificationItem) => {
    setNotificationModal(notification);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setNotificationModal(null);
    setOpenModal(false);
  };

  const handleCheckAsRead = async (id: string) => {
    await handleReadNotification(id);
  };

  const returnDate = (date?: string) => {
    if (date) {
      return moment(new Date(date)).format('DD MMM YYYY [às] HH[:]mm');
    }
  };

  const handleGetMoreNotifications = () => {
    setPage(page + 1);
    getNotifications(page + 1);
  };

  const renderNotifications = () => {
    if (notificationsLoading && !hasNotifications)
      return (
        <SkeletonLoadingArea>
          <Skeleton height={120} count={5} />
        </SkeletonLoadingArea>
      );

    if (!hasNotifications)
      return (
        <PanelNoNotification>
          <img src={noNotifications} alt="Sem notificações" />
          <h3>Nenhuma notificação</h3>
        </PanelNoNotification>
      );

    return (
      <NotificationArea>
        {sortedUnreadFirst?.map((notification) => (
          <NotificationAreaItem
            readed={notification.read}
            key={notification.id}
            onClick={() => handleOpenNotification(notification)}
          >
            <NotificationDescriptionArea>
              <img
                src={notification.read ? notificationsAlertDisabled : notificationAlert}
                alt="Notificação"
              />
              <div>
                <h3>{notification.title}</h3>
                <h4>{notification?.text?.slice(0, 100)}</h4>
              </div>
            </NotificationDescriptionArea>
            <NotificationFooter>
              <p>{returnDate(notification.post_date)}</p>
              {notification.read ? (
                <div>
                  <p>Lida</p>
                  <img src={checkedDisabled} alt="Lida" />
                </div>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckAsRead(notification.id);
                  }}
                >
                  Marcar como lida
                  <img src={checkedActive} alt="Marcar como lida" />
                </button>
              )}
            </NotificationFooter>
          </NotificationAreaItem>
        ))}
        {!finishedNotificationsList && (
          <ButtonLoadingMoreNotifications onClick={handleGetMoreNotifications}>
            Carregar mais...
          </ButtonLoadingMoreNotifications>
        )}
      </NotificationArea>
    );
  };

  return (
    <MainLayout
      title={
        unreadNotifications > 0
          ? `${unreadNotifications} notificações não lidas`
          : 'Notificações'
      }
      className="home"
    >
      <Container>
        <TitleArea>
          <Title>
            {unreadNotifications > 0
              ? `${unreadNotifications} notificações não lidas`
              : 'Notificações'}
          </Title>
        </TitleArea>
        <Panel height={heigthPanel} hasItems={hasNotifications}>
          {renderNotifications()}
        </Panel>
      </Container>
      <ModalNotifications
        handleCloseModal={handleCloseModal}
        notificationModal={notificationModal}
        openModal={openModal}
        returnDate={returnDate}
      />
    </MainLayout>
  );
}

export default connect()(Notifications);
