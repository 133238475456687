import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as cashbackActions } from '../../../redux/modules/cashback';

import FormCoupon from '../../../components/Cashback/FormCoupon';
import Statement from '../../../components/Cashback/Statement';
import Balance from '../../../components/Cashback/Balance';
import MainLayout from '../../../components/MainLayout';
import { ReactComponent as WalletSVG } from '../../../img/cashback/svg/Wallet.svg';

import './styles.scss';

export default function Cashback() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(cashbackActions.fetchTransactions());
        // eslint-disable-next-line
    }, []);

    return (
        <MainLayout className="cashback">
            <header>
                <div className="cashback-breadcrumb">
                    <WalletSVG />
                    <span>Carteira</span>
                </div>

                <div className="cashback-title">
                    <h1>Carteira</h1>
                </div>
            </header>   

            <main className="main">
                <section className="box-historic">
                    <Balance />

                    <Statement />
                </section>

                <FormCoupon />
            </main>

        </MainLayout>
    );
};