import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import Main from '../pages/main'
import Auth from '../pages/auth'
import ProtectedRoute from '../utils/ProtectedRoute.component'

import MainCheckout from '../pages/Checkout/Main';
import ModuleCheckout from '../pages/Checkout/Module';
import CheckoutNotFound from '../pages/Checkout/NotFound';

import StepsUnsubscriptionChannels from '../pages/gabarito/StepsUnsubscriptionChannels';

import gtm from '../shared/gtm';
import CfpSimuladoPdf from '../pages/CFP'
import cfpRelatorio from '../pages/CFP/Weekly/PDF/Class'
import CFPWeeklyPNG from '../pages/CFP/Weekly/PNG';

import Question from '../pages/questions';

function AppRoutes() {
  const locations = useLocation()
  useEffect(() => {
    gtm(window.location.pathname);
  }, [locations])

  function RedirectGabaritoro({ location }) {
    useEffect(() => {
      let newPathname = location.pathname;
  
      if (location.pathname.startsWith('/gabaritoro-modular')) {
        newPathname = newPathname.replace('/gabaritoro-modular', '/gabaritoro');
      }
  
      const targetUrl = `https://www.academiarafaeltoro.com.br${newPathname}${location.search}`;
      window.location.replace(targetUrl);
    }, [location]);
  
    return null;
  }
  

  return (
    <React.Suspense fallback={<Spin tip="Loading..." />}>
      <Switch>
        <Route path="/descadastrar/:token" component={StepsUnsubscriptionChannels} />
        <Route path={["/gabaritoro", "/gabaritoro-modular"]} render={(props) => <RedirectGabaritoro {...props} />} />

        <Route path="/cfp-simulado-pdf" component={CfpSimuladoPdf} />
        <Route path="/cfp-weekly-pdf" component={cfpRelatorio} />
        <Route path="/cfp-weekly-png" component={CFPWeeklyPNG} />

        <Route path="/checkout-notfound/" component={CheckoutNotFound} />
        <Route path="/checkout-modular/:curso/:id_turma/:afiliado?" component={ModuleCheckout} />
        <Route path="/newCheckout/:curso/:id_turma/:afiliado?" component={MainCheckout} />
        
        <Route path="/auth" component={Auth} />

        <Route path="/questions/:id" component={Question} />

        <ProtectedRoute path="/" component={Main} />
      </Switch>
    </React.Suspense>
  )
}

export default AppRoutes
