import styled, { css } from 'styled-components';
import start from '../../img/House.svg';
import startHover from '../../img/HouseHover.svg';
import courses from '../../img/GraduationCap.svg';
import coursesHover from '../../img/GraduationCapHover.svg';
import user from '../../img/UserCircle.svg';
import userHover from '../../img/UserCircleHover.svg';
import questions from '../../img/BookOpenText.svg';
import questionsHover from '../../img/BookOpenTextHover.svg';
import cashback from '../../img/Cardholder.svg';
import cashbackHover from '../../img/CardholderHover.svg';
import support from '../../img/Lifebuoy.svg';
import supportHover from '../../img/LifebuoyHover.svg';
import faq from '../../img/ChatCircleDots.svg';
import faqHover from '../../img/ChatCircleDotsHover.svg';
import logout from '../../img/SignOut.svg';
import logoutHover from '../../img/SignOutHover.svg';
import podcast from '../../img/headset.svg';
import podcastHover from '../../img/headset-hover.svg';
import gabaritoro from '../../img/FileText.svg';
import gabaritoroHover from '../../img/FileTextHover.svg';
import notifications from '../../img/Bell.svg';
import notificationsHover from '../../img/BellHover.svg';
import more from '../../img/DotsThreeOutline.svg';

type TItem = {
    id: string;
    border?: boolean;
}

type TBackgrounds = {
    [key: string]: {
        normal: string;
        hover: string;
    };
};

const backgrounds: TBackgrounds = {
    start: { normal: start, hover: startHover },
    courses: { normal: courses, hover: coursesHover },
    user: { normal: user, hover: userHover },
    questions: { normal: questions, hover: questionsHover },
    cashback: { normal: cashback, hover: cashbackHover },
    support: { normal: support, hover: supportHover },
    faq: { normal: faq, hover: faqHover },
    logout: { normal: logout, hover: logoutHover },
    podcast: { normal: podcast, hover: podcastHover },
    gabaritoro: { normal: gabaritoro, hover: gabaritoroHover },
    notifications: { normal: notifications, hover: notificationsHover },
    more: { normal: more, hover: more },
};

export const Container = styled.nav`
    padding-top: 13px;
    width: 100%;
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;

    @media(max-width: 768px) { 
        display: flex;
        justify-content: space-around;
        padding: 0;
        position: relative;
        box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.16);
    }
`;

export const Item = styled.div<TItem>`
    margin-bottom: 4px;    
    padding: 0 30px;
    height: 50px;
    color: #A4A4A4;
    cursor: pointer;
    display: flex;
    gap: 10px;

    &#more {
        display: none;
    }

    @media(max-width: 768px) { 
        padding: 0;
    }

    @media(max-width: 600px) {
        &#notifications {
            order: -1;
        }

        &:nth-child(1n + 4):not(#notifications):not(#more) {
            display: none;
            position: absolute;
            right: 1px;
            left: auto;
            bottom: 60px;
            background: #fff;
            width: 187px;
            padding: 0 10px;

            &.open {
                display: flex;
            }

            a, span {
                text-indent: 0;
            }

            &#logout {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding-bottom: 10px;
            }

            &#faq {
                bottom: 109px;
            }

            &#support {
                bottom: 158px;
            }

            &#cashback {
                bottom: 207px;
            }

            &#podcast {
                bottom: 256px;
            }

            &#questions {
                bottom: 305px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding-top: 10px;
            }
        }

        &#more {
            display: flex;
        }
    }

    a, span {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: space-between;
        align-items: center;
        color: #A4A4A4;
        padding-left: 31px;
        height: 30px;
        line-height: 30px;
        background: no-repeat 0 center;
        transition: all 0.3s ease-in-out;

        strong {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #D81E5B;
            text-align: center;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media(max-width: 768px) { 
            text-indent: -5000em;
            position: relative;

            strong {
                text-indent: 0;
                position: absolute;
                right: 3px;
                width: 16px;
                height: 16px;
                font-size: 10px;
                line-height: 16px;
            }
        }
    }

    &:hover {
        @media(min-width: 769px) {
            border-left: 3px solid #5768FF;
            padding-left: 27px;
            color: #5768FF;
        }

        a, span {
            color: #5768FF;

            strong {
                background-color: #5768FF;
            }
        }
    }

    ${({ id }) => backgrounds[id] && css`
        a, span {
            background-image: url(${backgrounds[id].normal});
        }

        &:hover {
            a, span {
                background-image: url(${backgrounds[id].hover});
            }
        }
    `}

    ${({ border }) => border && css`
        @media(min-width: 769px) {     
            position: relative;
            margin-top: 12px;

            &::before {
                content: '';
                position: absolute;
                top: -8px;
                left: 0;
                border: 1px solid #EFEFEF;
                width: 100%;
            }
        }
    `}
`;