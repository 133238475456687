import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { ArrowRightOutlined, PlayCircleOutlined, AlignLeftOutlined } from '@ant-design/icons';
import config from '../../config';
import RenderVideo from '../../components/RenderVideo.component';
import unchecked from '../../img/UncheckCircle.svg';
import checked from '../../img/CheckCircle.svg';
import checkedCorrect from '../../img/CheckCircleCorrect.svg';
import checkedIncorrect from '../../img/CheckedCircleIncorrect.svg';
import { 
  CodeStyled, 
  ContentStyled, 
  QuestionHeaderStyled, 
  AnswersStyled, 
  AnswerStyled, 
  QuestionFooterStyled, 
  ButtonStyled,
  ButtonStyledTwo,
  ResolutionStyled,
  TitleStyled,
  TextStyled,
  VideoStyled,
  RowStyledTwo, 
} from './styles';

function Question({data}) {
  const [questionAnswer, setQuestionAnswer] = useState();
  const [questionAnswerCorrect, setQuestionAnswerCorrect] = useState();
  const [resolution, setResolution] = useState();

  function renderImg(img) {
    if (img) {
      const domain = config.s3Url;
      const path = 'uploads/';
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img;
      
      return <img src={`${domain}${imgWithPath}`} alt="" style={{ marginTop: 10 }} />;
    }
  }

  function showResolution(type) {
    setResolution(type !== resolution ? type : undefined);
  }

  function checkAnswer() {
    const correctAnswer = data.awnsers.find(answer => answer.correta === 1).id_resposta;
    
    setQuestionAnswerCorrect(correctAnswer);
  }

  function selectAnswer(answer) {
    setQuestionAnswerCorrect(undefined);
    setQuestionAnswer(answer);
  }

  const checkIcon = (answerId, answerCorrect) => {
    if(answerId === questionAnswer) {
      if(questionAnswer === questionAnswerCorrect) {
        return <img src={checkedCorrect} alt="correto"/>;
      } else if(questionAnswerCorrect && questionAnswer !== questionAnswerCorrect) {
        return <img src={checkedIncorrect} alt="incorreto"/>;
      } else {
        return <img src={checked} alt="marcado"/>;
      }
    } else if(questionAnswerCorrect && answerCorrect) {
      return <img src={checkedCorrect} alt="correto"/>;
    } else {
      return <img src={unchecked} alt="desmarcado"/>;
    }
  }

  return (
    <div style={{width: '100%'}}>
      <Row gutter={[4, 20]} align="middle">
        <Col span={24} style={{paddingLeft: '24px'}}>
          <CodeStyled>{data.codigo ? <><span>Questão </span>{data.codigo}</> : 'Carregando...'}</CodeStyled>
        </Col>
      </Row>
      {data.codigo && (
        <>
          <RowStyledTwo>
            <Col span={24}>
              <ContentStyled 
                resolution={resolution} 
                textResolution={!!data.questao_comentada} 
                videoResolution={!!data.video_embed}
              >
                <QuestionHeaderStyled>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{data.enunciado}</p>
                  {renderImg(data.img_enunciado)}
                </QuestionHeaderStyled>
                <AnswersStyled>
                  {data.awnsers?.filter((answer) => answer.resposta)?.map((answer) => (
                    <AnswerStyled 
                      key={answer.id_resposta}
                      checked={answer.id_resposta === questionAnswer} 
                      incorrect={questionAnswerCorrect && answer.id_resposta !== questionAnswerCorrect} 
                      correct={questionAnswerCorrect === answer.id_resposta} 
                      onClick={() => selectAnswer(answer.id_resposta)}
                    >
                      {checkIcon(answer.id_resposta, answer.correta)} {answer.resposta}
                    </AnswerStyled>
                  ))}
                </AnswersStyled>
              </ContentStyled>
            </Col>
          </RowStyledTwo>
          {resolution && (
            <Row>
              <Col span={24}>
                  <ResolutionStyled type={resolution}>
                    <TitleStyled>Resolução em {resolution}</TitleStyled>
                    {resolution === 'texto' ? <TextStyled>{data.questao_comentada}</TextStyled> : <VideoStyled><RenderVideo videoEmbed={data.video_embed} /></VideoStyled>}
                  </ResolutionStyled>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <QuestionFooterStyled>
                {data.questao_comentada && <ButtonStyledTwo type='default' active={resolution === 'texto'} onClick={() => showResolution('texto')}><AlignLeftOutlined /> Ler a resolução</ButtonStyledTwo>}
                {data.video_embed && <ButtonStyledTwo type='default' active={resolution === 'vídeo'} onClick={() => showResolution('vídeo')}><PlayCircleOutlined /> Assistir a resolução</ButtonStyledTwo>}
                <ButtonStyled onClick={checkAnswer} disabled={!questionAnswer} className='seeResult' type='primary'>Salvar e ver resultado <ArrowRightOutlined /></ButtonStyled>
              </QuestionFooterStyled>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Question;
