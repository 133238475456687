import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as podcastActions, PodcastState } from '../../../../redux/modules/podcast';

import MainLayout from '../../../../components/MainLayout';
import Player from '../../../../components/Podcast/Player';
import Album from '../../../../components/Podcast/Album';
// import Footer from '../../../../components/Podcast/Footer';
import { ReactComponent as BlueHeadset } from '../../../../img/podcast/BlueHeadset.svg';
import { ReactComponent as BlueInfo } from '../../../../img/podcast/BlueInfo.svg';
import useDocumentSize from '../../../../hooks/useDocumentSize';

import '../styles.scss';

export default function Podcast () {

    const dispatch = useDispatch();
    const { currentPodcast, currentPlay } = useSelector((state: { podcast: PodcastState }) => state.podcast);
    const { width } = useDocumentSize();

    useEffect(() => {
        dispatch(podcastActions.fetchAll());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (currentPodcast.id && currentPlay.isPlaying) {
            dispatch(podcastActions.openPlayerFooter());
        } else {
            dispatch(podcastActions.closePlayerFooter());
        }
        // eslint-disable-next-line
    }, [currentPodcast.id, currentPlay.isPlaying]);

    return (
        <MainLayout>
            <div className="podcast-box-title">
                <div className="title">
                    <BlueHeadset />
                    <span>{width<728 ? 'Ouça o Podcast' : 'Podcast'}</span>
                </div>
                <div className="info">
                    <BlueInfo />
                </div>
            </div>
            <div className="podcast">
                <header>
                    <h1>Toro Podcast</h1>
                </header>
                <section className="content">
                    <div className="album">
                        <Album />
                    </div>
                    <aside>
                        <div className="podcast-player">
                            <Player />
                            <BlueInfo className="info"/>
                        </div>
                        {/*
                            currentPodcast?.id && (
                                <div className="podcast-footer">
                                    <Footer />
                                </div>
                            )
                            */}
                    </aside>
                </section>
            </div>
        </MainLayout>
    );
};